import { useState, useCallback, type FormEvent, useEffect } from "react";
import styles from "./FormsV2.module.scss";
import { motion } from "framer-motion";
import { Check } from "lucide-react";

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  query: string;
}

interface FormErrors {
  [key: string]: string;
}

interface Props {
  errors: { [key: string]: string };
  setErrors?: (
    value:
      | { [key: string]: string }
      | ((prev: { [key: string]: string }) => { [key: string]: string })
  ) => void;
}

const INITIAL_FORM_STATE: FormData = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  query: "",
};

const FORM_VALIDATIONS = {
  firstName: (value: string) => (!value ? "First Name is required" : ""),
  lastName: (value: string) => (!value ? "Last Name is required" : ""),
  email: (value: string) => {
    if (!value) return "Email is required";
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)
      ? ""
      : "Email is not valid";
  },
  phoneNumber: (value: string) => {
    if (!value) return "Phone Number is required";
    return /^[6-9]\d{9}$/.test(value) ? "" : "Phone Number is not valid";
  },
  query: () => "",
} as const;

const SCRIPT_URL =
  "https://script.google.com/macros/s/AKfycbwPm4dfwLcpukB8Uc4sZd0ZtEhC9x34DhHxA3u43e8eJt23gPw81lZ4-GI57GN7IgKJfQ/exec";

export default function ContactForm({ errors, setErrors = () => {} }: Props) {
  const [formData, setFormData] = useState<FormData>(INITIAL_FORM_STATE);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleInputChange = useCallback(
    (field: keyof FormData, value: string) => {
      setFormData((prev) => ({ ...prev, [field]: value }));
      setErrors((prev) => ({ ...prev, [field]: "" }));
    },
    []
  );

  const validateForm = useCallback((): FormErrors => {
    const newErrors: FormErrors = {};

    (Object.keys(formData) as Array<keyof FormData>).forEach((field) => {
      const validationError = FORM_VALIDATIONS[field](formData[field]);
      if (validationError) {
        newErrors[field] = validationError;
      }
    });

    return newErrors;
  }, [formData]);

  useEffect(() => {}, []);

  const handleSubmit = async (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const formErrors = validateForm();
    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      setIsSubmitting(true);

      try {
        const formDataObj = new URLSearchParams();
        Object.entries(formData).forEach(([key, value]) => {
          formDataObj.append(key, value);
        });

        const response = await fetch(SCRIPT_URL, {
          method: "POST",
          body: formDataObj,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        await response.json();
        setIsSuccess(true);
        setFormData(INITIAL_FORM_STATE);
      } catch (error) {
        console.error("Submission error:", error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const getFieldLabel = (field: keyof FormData): string => {
    return field
      .replace(/([A-Z])/g, " $1")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  if (isSuccess) {
    return <SuccessScreen onResubmit={() => setIsSuccess(false)} />;
  }

  return (
    <div className={styles["wrapper"]}>
      <div className={styles["segment"]}>
        <h1>GET IN TOUCH</h1>
      </div>
      {(Object.keys(formData) as Array<keyof FormData>).map((field) => (
        <div key={field} style={{ width: "100%" }}>
          <label className={styles["label"]}>
            <input
              className={styles["input"]}
              type={field === "email" ? "email" : "text"}
              value={formData[field]}
              onChange={(e) => handleInputChange(field, e.target.value)}
              // className={errors[field] ? "error" : ""}
              placeholder={getFieldLabel(field)}
              required
            />
            {errors[field] && (
              <span className={styles["error-text"]}>{errors[field]}</span>
            )}
          </label>
        </div>
      ))}

      <button
        className={styles["button"]}
        type="submit"
        onClick={handleSubmit}
        disabled={isSubmitting}
      >
        {isSubmitting ? "Submitting..." : "Submit"}
      </button>
    </div>
  );
}

const SuccessScreen = ({ onResubmit }: any) => {
  const containerVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut",
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 },
    },
  };

  return (
    <div className={styles.wrapper}>
      <motion.div
        className={styles.segment}
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {/* Success Icon */}
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ type: "spring", stiffness: 200, damping: 15 }}
        >
          <motion.div
            style={{ display: "inline-block" }}
            whileHover={{ scale: 1.1 }}
          >
            <Check style={{ height: "84px", width: "84px" }} color="#ae1100" />
          </motion.div>
        </motion.div>

        {/* Success Message */}
        <motion.h1 variants={itemVariants} style={{ marginTop: "16px" }}>
          Thank You!
        </motion.h1>

        <motion.div variants={itemVariants} style={{ margin: "16px 0" }}>
          <p>Your message has been successfully sent.</p>
          <p>We'll get back to you soon!</p>
        </motion.div>

        {/* Resubmit Button */}
        <motion.button
          onClick={onResubmit}
          className={styles.button}
          variants={itemVariants}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          Submit Another Message
        </motion.button>
      </motion.div>
    </div>
  );
};
