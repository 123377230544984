import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase-config";
import styles from "./Projects.module.scss";
import { motion } from "framer-motion";
import { Cross } from "lucide-react";
import { useNavigate } from "react-router";

interface Project {
  title: string;
  srcImg: string;
  status: string;
  images: string[];
  description: string;
  videos: string[];
}

const ProjectsGrid = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [loadingImages, setLoadingImages] = useState<{
    [key: string]: boolean;
  }>({});
  const navigate = useNavigate();

  const onClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchProjects = async () => {
      const projectsCollection = collection(db, "projects");
      const projectsSnapshot = await getDocs(projectsCollection);
      const projectsList = projectsSnapshot.docs.map(
        (doc) => doc.data() as any
      );
      setProjects(projectsList);

      // Initialize loading states
      const initialLoadingState = projectsList.reduce(
        (acc: any, project: Project) => {
          acc[project.srcImg] = true;
          project.images.forEach((img) => (acc[img] = true));
          return acc;
        },
        {}
      );
      setLoadingImages(initialLoadingState);
    };
    fetchProjects();
  }, []);

  const handleImageLoad = (imgSrc: string) => {
    setLoadingImages((prev) => ({ ...prev, [imgSrc]: false }));
  };

  return (
    <div className={styles["projects-wrapper"]}>
      <motion.button
        onClick={onClick}
        className={styles.backButton}
        initial={{ x: 0, opacity: 0, rotate: 0 }}
        animate={{ x: 0, opacity: 1, rotate: 45 }}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <Cross size={14} />
      </motion.button>
      <h1>PROJECTS</h1>
      <div className={styles.projectsContainer}>
        {projects.map((project, idx) => (
          <div key={idx} className={styles.projectItem}>
            <div className={styles.projectHeader}>
              <h2 className={styles.projectTitle}>{project.title}</h2>
              <span className={styles.projectStatus}>{project.status}</span>
            </div>

            <div className={styles.imageScroller}>
              <div className={styles.imageContainer}>
                {/* Source Image First */}
                <div className={styles.imageWrapper}>
                  {loadingImages[project.srcImg] && (
                    <div className={styles.skeleton} />
                  )}
                  <img
                    src={project.srcImg}
                    alt={`${project.title} main`}
                    className={styles.projectImage}
                    onLoad={() => handleImageLoad(project.srcImg)}
                    style={{
                      display: loadingImages[project.srcImg] ? "none" : "block",
                    }}
                  />
                </div>

                {/* Other Images */}
                {project.images.map((image, index) => (
                  <div key={index} className={styles.imageWrapper}>
                    {loadingImages[image] && (
                      <div className={styles.skeleton} />
                    )}
                    <img
                      src={image}
                      alt={`${project.title} ${index + 1}`}
                      className={styles.projectImage}
                      onLoad={() => handleImageLoad(image)}
                      style={{
                        display: loadingImages[image] ? "none" : "block",
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectsGrid;
