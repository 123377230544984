// HexagonLoader.tsx
import React from "react";
import "./LandingLoader.scss";

interface HexagonLoaderProps {
  text?: string;
  duration?: number;
}

const HexagonLoader: React.FC<HexagonLoaderProps> = ({
  text = "Loading",
  duration = 8,
}) => {
  const groups = Array.from({ length: 6 }, (_, i) => i);
  const sectors = Array.from({ length: 6 }, (_, i) => i);

  return (
    <div className="loader-container">
      <div
        className="hexagon"
        aria-label="Animated hexagonal ripples"
        style={{ "--dur": `${duration}s` } as React.CSSProperties}
      >
        {groups.map((group) => (
          <div key={group} className="hexagon__group">
            {sectors.map((sector) => (
              <div key={sector} className="hexagon__sector"></div>
            ))}
          </div>
        ))}
      </div>
      <p aria-label="Loading">{text}</p>
    </div>
  );
};

export default HexagonLoader;
