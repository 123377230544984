import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import Forms from "./Forms";
import ctaPng from "../assets/ctaPng.png";

type NavigationType = "scroll" | "route" | "click";

interface FormErrors {
  [key: string]: string;
}

interface BaseMenuItem {
  text: string;
  delay: number;
  type: NavigationType;
}

interface ScrollMenuItem extends BaseMenuItem {
  type: "scroll";
  sectionId: string;
}

interface RouteMenuItem extends BaseMenuItem {
  type: "route";
  path: string;
}

interface ClickMenuItem extends BaseMenuItem {
  type: "click";
  onClick: () => void;
}

type MenuItem = ScrollMenuItem | RouteMenuItem | ClickMenuItem;

interface NavMenuDropdownProps {
  showDropdown: boolean;
  toggleDropdown: (value: boolean) => void;
}

const NavMenuDropdown: React.FC<NavMenuDropdownProps> = ({
  showDropdown,
  toggleDropdown,
}) => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const [errors, setErrors] = useState<FormErrors>({});

  const handleExpand = () => {
    setIsExpanded(true);
  };

  const menuItems: MenuItem[] = [
    {
      text: "Home",
      type: "route",
      path: "/",
      delay: 0,
    },
    {
      text: "Projects",
      type: "route",
      path: "/projects",
      delay: 0.1,
    },
    // {
    //   text: "Gallery",
    //   type: "route",
    //   path: "/gallery",
    //   delay: 0.2,
    // },
    {
      text: "Questions",
      type: "scroll",
      sectionId: "faq",
      delay: 0.3,
    },
    {
      text: "About",
      type: "scroll",
      sectionId: "footer",
      delay: 0.4,
    },
    {
      text: "Contact",
      type: "click",
      onClick: handleExpand,
      delay: 0.5,
    },
  ];

  const handleItemClick = (item: MenuItem): void => {
    toggleDropdown(false);
    switch (item.type) {
      case "scroll": {
        const element = document.getElementById(item.sectionId);
        if (element) {
          var headerOffset = 205;
          var elementPosition = element.getBoundingClientRect().top;
          var offsetPosition =
            elementPosition + window.pageYOffset - headerOffset;

          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        }
        break;
      }

      case "route": {
        navigate(item.path);
        break;
      }

      case "click": {
        item.onClick();
        break;
      }
    }
  };

  return (
    <>
      <div
        className="red-form-style"
        style={{
          position: "fixed",
          overflow: "hidden",
          height: "100vh",
          width: "100vw",
          display: "hidden",
          pointerEvents: "none",
        }}
      >
        <motion.div
          initial={{
            opacity: 0,
            position: "absolute",
            top: "0%",
            left: "-100%",
            width: "100%",
            height: "100%",
          }}
          animate={
            isExpanded
              ? { opacity: 1, position: "fixed", left: 0, pointerEvents: "all" }
              : {}
          }
          transition={{ duration: 0.5 }}
          style={{
            color: "white",
            zIndex: 10,
            display: isExpanded ? "flex" : "none",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            className="close-button"
            onClick={() => {
              setIsExpanded(false);
              setErrors({});
            }}
          >
            <div className={` line1 ${isExpanded ? "" : "spin"}`}></div>
            <div className={` line2 ${isExpanded ? "" : "spin"}`}></div>
          </div>
          <Forms errors={errors} setErrors={setErrors} />
        </motion.div>

        <motion.div
          onClick={handleExpand}
          initial={{
            width: "0px",
            height: "0px",
            borderRadius: "50%",
            backgroundColor: "#E73C37",
          }}
          animate={
            isExpanded
              ? {
                  width: "3000px",
                  height: "3000px",
                  borderRadius: "0%",
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  bottom: "0%",
                  pointerEvents: "all",
                }
              : {}
          }
          transition={{ duration: 1 }}
          style={{
            backgroundColor: "#E73C37",
            position: "absolute",
            bottom: "40%",
            right: 0,
            zIndex: 2,
            cursor: "pointer",
          }}
        >
          {!isExpanded && (
            <div className="main-cta-style" onClick={handleExpand}>
              <img src={ctaPng} alt="cta" />
            </div>
          )}
        </motion.div>
      </div>
      <nav className={`nav-bar`}>
        {!showDropdown && (
          <>
            <Link className="head" to="/">
              <img src={logo} alt="logo" />
            </Link>
            <div
              className="menu-btn"
              onClick={() => {
                toggleDropdown(true);
              }}
            >
              <div className="menu">
                <div className="hamburger-menu">
                  <div className="line-menu"></div>
                  <div className="line-menu"></div>
                  <div className="line-menu"></div>
                </div>
              </div>
            </div>
          </>
        )}
      </nav>
      <AnimatePresence>
        <motion.div
          className={`nav-menu-dropdown ${
            showDropdown ? "nav-menu-dropdown-open" : "nav-menu-dropdown-hidden"
          }`}
          initial="hidden"
          animate={showDropdown ? "visible" : "hidden"}
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
        >
          <div className="nav-section-1">
            {menuItems.map((item) => (
              <motion.div
                key={item.text}
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{
                  duration: 0.3,
                  delay: showDropdown ? item.delay : 0,
                  ease: "easeOut",
                }}
                onClick={() => handleItemClick(item)}
                role="button"
                tabIndex={0}
                style={{ cursor: "pointer" }}
                onKeyPress={(e: React.KeyboardEvent) => {
                  if (e.key === "Enter") {
                    handleItemClick(item);
                  }
                }}
              >
                {item.text}
              </motion.div>
            ))}
          </div>
          <div className="nav-section-2">
            <motion.div
              onClick={() => toggleDropdown(false)}
              initial={{ x: -20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{
                duration: 0.3,
                delay: showDropdown ? 0.5 : 0,
                ease: "easeOut",
              }}
              style={{ cursor: "pointer" }}
            >
              Close
            </motion.div>
          </div>
        </motion.div>
      </AnimatePresence>
    </>
  );
};

export default NavMenuDropdown;
